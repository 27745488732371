import React, { Component } from "react";
import { connect } from "react-redux";
import { Link,useParams } from "react-router-dom";
import axios from "axios";
import { Field, reduxForm, reset } from "redux-form";
import { ROOT_URL, APP_URL } from "../helper/Config";
import { FormattedMessage,FormattedHTMLMessage } from "react-intl";
import { login, logout, clearMessage, setLocale } from "../actions";
import { register} from "../actions";
import $ from "jquery";
import { history } from "../helper/History";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: 0,
     roles:[],
      lang: null,

     
    };
     setTimeout(() => {
      // Remove the item from localStorage after the specified time
      localStorage.removeItem('alert2');
    }, 120000);
    window.scrollTo(0,0)
    axios.get(`${ROOT_URL}/getRoles`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }).then(response => {
        // console.log(response);
        this.setState({
          roles: response.data.roles
        });
      }).catch(error => {
        // console.log(error);
      });
      var userType = localStorage.getItem("role");
      // console.log(userType)
      if((userType)&(userType)=='User'){
        history.push("/");     
    }
    }

 
  renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div className="form-group">
      <label>{label}</label>
      <input
        {...input}
        placeholder=''
        type={type}
        className="form-control"
      />
      {touched && (error && <small className="text-danger">{error}</small>)}
    </div>
  );
  
  renderSelectField = ({
    input,
    label,
    meta: { touched, error },
    children,
    
  }) => (
    <div className="form-group">
      <label>{label}</label>
      <select {...input}  value={this.state.userRole} selected className="form-control">
        {children}
      </select>
      {touched && (error && <small className="text-danger">{error}</small>)}
    </div>
  ); 

  registerUser(values) {
    console.log(values);
    $("#register-loader").show();
    this.refs.registerBtn.setAttribute("disabled", "disabled");
   // pass the languages in values \
    var language = localStorage.getItem("lang");
    values.language = language;
    const path = window.location.pathname;
    const param = path.split('/')[2]; 
    console.log(param);
    if(param == 'ft-h') {
      values.registered_by = 'FT-Happiness';
      values.role = 2;
    }
    if(param == 'ft-t') {
      values.registered_by = 'FT-Target';
      values.role = 2;
    }
    //console.log('registerUser '+JSON.stringify(values, null, 4));

    this.props.register(values, this.refs.registerBtn, response => {
      //console.log(' errors++++ '+JSON.stringify(response)); 
      if (response === "success") {
        this.props.reset("RegisterValidation");
        // on successfull signup show login form
     
        if(localStorage.getItem("alert2")== 2){ 
          localStorage.setItem('guide','1')
          // localStorage.removeItem("alert2")
          localStorage.removeItem('alert2');
          
          this.props.history.push("/individual")}
          else if(localStorage.getItem("alert2")== 1){
            localStorage.setItem('guide','2')
            localStorage.removeItem("alert2")
        this.props.history.push("/hr")}
        else if
        (localStorage.getItem("alert2")== 3){
          localStorage.setItem('guide','3')
          localStorage.removeItem("alert2")
          
           this.props.history.push("/talentcompy")}
           
           else if
           (localStorage.getItem("redirectpage")=="Enterprise"){
             localStorage.removeItem("redirectpage")
              this.props.history.push("/login/Enterprise")}
              else if
              (localStorage.getItem("redirectpage")=="Consultant"){
                localStorage.removeItem("redirectpage")
                 this.props.history.push("/login/Consultant")}
                 else if
                 (localStorage.getItem("redirectpage")=="Consultant"){
                   localStorage.removeItem("redirectpage")
                    this.props.history.push("/login/ConBuytest")}
           else{

         this.props.history.push("/login");
           }
          
        // $("#login-form").delay(100).fadeIn(100);
        // $("#register-form").fadeOut(100);
        // $("#register-form-link").removeClass("active");
        $('#login-form-link').addClass("active");
      }else{
        // console.log(' errors*** '+response);
        $("#loginModal").scrollTop(0);
        // document.getElementById("email").style.display="block"

        // return (
        // //  <div className="alert alert-danger" role="alert">
        // //     {console.log("Email already Exist.")}
        // //   </div>
        // );

        // this.props.history.push("/register");
        
        
      }
    });
  }

  renderRoles(roles) {

    
    
    // console.log(JSON.stringify(this.props.lang)+' -- '+localStorage.getItem("lang"));
    // var ind=<FormattedMessage id="lang.role" defaultMessage=""/>
    // var prof=<FormattedMessage id="lang.role2" defaultMessage=""/>

    if(localStorage.getItem("lang")!== "en"){
      var prof = 'TPE-PME ou Cabinet';
      var ent  = 'Grand Groupe';
      var ind  = 'Particulier';
    }else{
      var prof = 'VSE-SME or firm';
      var ent  = 'Big Group';
      var ind  = 'Individual'; 
    }
     const selected = "selected";
    if (this.state.roles.length > 0) {
   
      return this.state.roles.map(role => {

        return (
          <option key={role.id}  value={role.id}  className="needsclick" selected = {role.id === 2}  >            
            {role.id === 3 ? prof :role.id === 4 ? ent : ind } 
            {/* {role.name} */}
          </option>
        );
      });
    }
  }

  showLogin(e) {
    $("#login-form").delay(100).fadeIn(100);
    $("#register-form").fadeOut(100);
    $("#register-form-link").removeClass("active");
    //console.log('Inside Register'+e);
    if(e === '#login-form-link'){
      $('.modal-title').text('LOGIN');            
      $(e).addClass("active");
    }else{
      $(e.target).addClass("active");
    }
  }

  changeUserRole(e) {
    // console.log('Hurrey '+e.target.role); 
    if(e.target.value == 2){
      this.setState({
        userRole: 2
      });   
      // console.log(this.state.userRole);   
      $("#hrDetail").hide();
    }
    else if(e.target.value == 4 ){
      $("#hrDetail").show();
      this.setState({
        userRole: 4
      });           
     
    }
    else if(e.target.value === null ){
      $("#hrDetail").show();
      this.setState({
        userRole: 3
      });           
     
    }else{
      $("#hrDetail").show();
      this.setState({
        userRole: 3
      });  
    }
  }
  errorMessage() {
   

    // console.log(this.props.signupError+' --  '+this.props.successMessage+' props '+JSON.stringify(this.props));
    // check for signup error message
    if (this.props.errorMessage == "The email has already been taken. ") {
      return (
        <div  class=" alert alert-danger alert-dismissible fade show "  role="alert">
          {this.props.errorMessage}
        </div>
      );
    }
    else if (this.props.errorMessage !== null && this.props.errorMessage !== "" && this.props.errorMessage !== "The email has already been taken.") {
      return (
        <div  class="alert alert-danger alert-dismissible fade show "  role="alert">
          {this.props.errorMessage}
        </div>
      );
    }
    // check for loginn error message
    // if (this.props.errorMessage !== null && this.props.errorMessage !== "") {
    //   return (
    //     <div className="alert alert-danger" role="alert">
    //       {this.props.errorMessage}
    //     </div>
    //   );
    // }
    // if (
    //   this.props.successMessage !== null &&
    //   this.props.successMessage !== ""
    // ) {
    //   return (
    //     <div className="alert alert-success" role="alert">
    //       {this.props.successMessage}
    //     </div>
    //   );
    // }

    setTimeout(() => {
      this.props.clearMessage();
    }, 60000);
  }

  errMessage() {
  
  
    if (
      localStorage.getItem("alert2")=='1'
    ) {
      return (

        <div class="alert alert-success alert-dismissible fade show " role="alert" style={{ fontSize:"13px" }}>
          <FormattedHTMLMessage id="alert2"/>
        <button type="button" class="close" onClick={(e)=>localStorage.removeItem("alert2")} data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      );
    
    }else if(
      localStorage.getItem("alert2")=='2'
    ) {
      return (

        <div class="alert alert-success alert-dismissible fade show " role="alert">
          <FormattedHTMLMessage id="alert3"/>
        <button type="button" class="close" onClick={(e)=>localStorage.removeItem("alert2")} data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      );
    
    }
    else if(
      localStorage.getItem("alert2")=='3'
    ) {
      return (

        <div class="alert alert-success alert-dismissible fade show " role="alert">
          <FormattedHTMLMessage id="alert4"/>
        <button type="button" class="close" onClick={(e)=>localStorage.removeItem("alert2")} data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      );
    
    }
    else{
    localStorage.removeItem("alert2");
    }
    setTimeout(() => {
      this.props.clearMessage();
    }, 80000);
    
  }

  msgMessage() {
    //console.log(this.props.signupError+' --  '+this.props.successMessage+' props '+JSON.stringify(this.props));
    // // check for signup error message
    // if (this.props.signupError !== null && this.props.signupError !== "") {
      return (
        <div class="alert alert-success alert-dismissible fade show " role="alert">
             <FormattedHTMLMessage
                      id="Link.Message"
                      defaultMessage="Login"
                    />
      <button type="button" class="close" onClick={(e)=>localStorage.removeItem("alert2")} data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
     
      );
    // }
    // check for loginn error message
 
    

    // setTimeout(() => {
    //   this.props.clearMessage();
    // }, 90000);
  }

 

  render() {
    const { Roles } = this.props;
    const firstname  = <FormattedMessage id="signup.firstname" defaultMessage="First Name"/>
    const lastname  = <FormattedMessage id="signup.lastname" defaultMessage="Last Name"/>
    const emailaddress  = <FormattedMessage id="signup.email" defaultMessage="Email"/>
    const address  = <FormattedMessage id="signup.address" defaultMessage="address"/>
    const password  = <FormattedMessage id="signup.password" defaultMessage="Password"/>
    const cpassword  = <FormattedMessage id="signup.cpassword" defaultMessage="Confirm Password"/>
    const youare  = <FormattedMessage id="signup.youare.hr" defaultMessage="Are you Individual / Professional *"/>
   
    //var language = localStorage.getItem("lang");
    //console.log('Render '+language);
    // console.log(this.props);
    if(this.state.userRole == 3){
      //console.log(' Inside  '+JSON.stringify(this.state.userRole));
      const phonenoHr  = <FormattedHTMLMessage id="signup.phone.hr" defaultMessage="Phone No *"/>
      const company  = <FormattedHTMLMessage id="signup.company" defaultMessage="Compay Name *"/>
      const designationHr  = <FormattedHTMLMessage id="signup.designation.hr" defaultMessage="Designation *"/>
      const addresss  = <FormattedMessage id="signup.addresss" defaultMessage="address"/>
      
      return (
     
        <div className="col-md-6 col-sm-12 mx-auto col"  style={{marginTop:"10%"}}>
          {/* <div id="email" style={{display:"none"}}>
          <div className="alert alert-danger"  role="alert">
            Email already Exist.
          </div>
          </div> */}
              <h4 className="modal-title text-center text-uppercase w-100 medium text-grey font-20">
                   <FormattedMessage
                      id="head.register"
                      defaultMessage="register"
                    />
                </h4>
             
                {this.errMessage()}
                {this.errorMessage()}
               
                {/* padding:"2px 460px" */}
          <form
            id="register-form"
            onSubmit={this.props.handleSubmit(e => this.registerUser(e))}
            style={{display:"block",padding: "15px 26px",border: "1px solid #000"}}
        
          >
            {/* if(localStorage.getItem("lang")!== "en"){
      var ind  = 'Particulier';
      var prof = 'Professionnel';
    }else{
      var ind  = 'Individual';
      var prof = 'Professional';
     
    } */}
            <Field name="role" component={this.renderSelectField} label={youare} onChange={e => this.changeUserRole(e)} > 
              <option defaultValue="">Register as</option> 
              {this.renderRoles(Roles)}
            </Field>   

            {/* <select name="role" label={youare} onChange={e => this.changeUserRole(e)}>
              <option value="2" selected>Particulier</option>
              <option value="3">Professionnel</option>
            </select> */}
            {/* <div> 
            <select
                      defaultValue="particular"
                                     className="form-control" id="partial_full_disability"
                                       name="role" onChange={e => this.changeUserRole(e)} onSelect={value => this.changeUserRole(value)}> 
                                        <option value="0">Select partial full disability</option>
                                           <option
                                            value={1}>Particular
                                              </option>
                                              <option
                                               value={1}>Individual
                                                </option>
            </select>     
            </div> */}
            <Field
              name="firstName"
              type="text"
              component={this.renderField}
              label={firstname}
            
            />
            <Field
              name="lastName"
              type="text"
              component={this.renderField}
              label={lastname}
            />
            <Field
              name="email"
              type="email"
              component={this.renderField}
              label={emailaddress}
            />
             {this.errorMessage()}
            <Field
              name="address"
              type="text"
              component={this.renderField}
              label={addresss}
            />
            <Field
              name="phone"
              type="tel"
              component={this.renderField}
              label={phonenoHr}
            />
            <div id="hrDetail" style={{ display: ""}}>
              <Field
                name="companyName"
                type="text"
                component={this.renderField}
                label={company}
              />
              <Field
                name="designation"
                type="text"
                component={this.renderField}
                label={designationHr}
              />
            </div>
            <Field
              name="password"
              type="password"
              component={this.renderField}
              label={password}
            />
            <Field
              name="confirmPass"
              type="password"
              component={this.renderField}
              label={cpassword}
            />
             
            <div className="form-group">
              <div className="row">
              <FormattedMessage id="signup.mandaory" defaultMessage="* mandatory"/>
                <div className="m-auto d-flex">
                  <button type="submit" name="register-submit" id="register-submit" tabIndex="4"  className="form-control btn btn-primary mr-3 whitespacenowrap" ref="registerBtn">
                    <FormattedMessage id="signup.signup" defaultMessage=""/>
                  </button>
                  {/* <button type="button" name="register-submit" id="register-submit" tabIndex="4"  className="form-control btn btn-secondary whitespacenowrap" ref="sbmt" onClick={e => this.showLogin('#login-form-link')}>
                    <FormattedMessage id="signup.login" defaultMessage="lOG IN"/>
                  </button> */}
                </div>
              </div>
              <hr/>
              <div className="row">
                <div className="m-auto d-flex">
                  <FormattedMessage id="siignup.click.desc" defaultMessage="If you already registered click to"/>&nbsp;  &nbsp; 
                  <Link className="" to="/login">
                    <FormattedMessage id="signup.login" defaultMessage="lOG IN"/>
                  </Link>
                </div>
              </div>                 
            </div>
            <div className="float-left">
              <div
                className="spinner-border text-primary ml-2"
                role="status"
                id="register-loader"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </form>
        </div>
      );      
    }else if(this.state.userRole == 4){
      //console.log(' Inside  '+JSON.stringify(this.state.userRole));
      const phonenoInd  = <FormattedHTMLMessage id="signup.phone" defaultMessage="Phone No *"/>
      const companyInd  = <FormattedHTMLMessage id="signup.company" defaultMessage="Compay Name *"/>
      const designationInd  = <FormattedHTMLMessage id="signup.designation" defaultMessage="Designation *"/>
          
      return (
        <div className="col-md-6 col-sm-12 mx-auto col"  style={{marginTop:"10%"}}>
           {/* <div id="email" style={{display:"none"}}>
          <div className="alert alert-danger"  role="alert">
            Email already Exist.
          </div>
          </div> */}
             <h4 className="modal-title text-center text-uppercase w-100 medium text-grey font-20">
                   <FormattedMessage
                      id="head.register"
                      defaultMessage="register"
                    />
                </h4>
                {this.errMessage()}
                {this.errorMessage()}
          <form
            id="register-form"
            onSubmit={this.props.handleSubmit(e => this.registerUser(e))}
            style={{display:"block",padding: "15px 26px",border: "1px solid #000"}}
          >
            <Field name="role" component={this.renderSelectField} label={youare} onChange={e => this.changeUserRole(e)} >
               {/* <option value="">Registered as</option> */}
              {this.renderRoles(Roles)}
            </Field>          
             {/* <div> 
            <select
                      defaultValue="particular"
                                     className="form-control" id="partial_full_disability"
                                       name="role" onChange={e => this.changeUserRole(e)} onSelect={value => this.changeUserRole(value)}>
                                        <option value="0">Select partial full disability</option>
                                           <option
                                            value={1}>Particular
                                              </option>
                                              <option
                                               value={1}>Individual
                                                </option>
            </select>     
            </div> */}
            <Field
              name="firstName"
              type="text"
              component={this.renderField}
              label={firstname}
              // role='2'
              
            />
            <Field
              name="lastName"
              type="text"
              component={this.renderField}
              label={lastname}
            />
            <Field
              name="email"
              type="email"
              component={this.renderField}
              label={emailaddress}
            />
             {this.errorMessage()}
              <Field
              name="address"
              type="text"
              component={this.renderField}
              label={address}
            />
            <Field
              name="phone"
              type="tel"
              component={this.renderField}
              label={phonenoInd}
            />
            {/* <div id="hrDetail" style={{ display: "none"}}> */}
              <Field
                name="companyName"
                type="text"
                component={this.renderField}
                label={companyInd}
              />
              <Field
                name="designation"
                type="text"
                component={this.renderField}
                label={designationInd}
              />
            {/* </div> */}
            <Field
              name="password"
              type="password"
              component={this.renderField}
              label={password}
            />
            <Field
              name="confirmPass"
              type="password"
              component={this.renderField}
              label={cpassword}
            />
             
            <div className="form-group">
              <div className="row">
              <FormattedMessage id="signup.mandaory" defaultMessage="* mandatory"/>
                <div className="m-auto d-flex">
                  <button type="submit" name="register-submit" id="register-submit" tabIndex="4"  className="form-control btn btn-primary mr-3 whitespacenowrap" ref="registerBtn">
                    <FormattedMessage id="signup.signup" defaultMessage=""/>
                  </button>
                  {/* <button type="button" name="register-submit" id="register-submit" tabIndex="4"  className="form-control btn btn-secondary whitespacenowrap" ref="sbmt" onClick={e => this.showLogin('#login-form-link')}>
                    <FormattedMessage id="signup.login" defaultMessage="lOG IN"/>
                  </button> */}
                </div>
              </div>
              <hr/>
              <div className="row">
                <div className="m-auto d-flex">
                  <FormattedMessage id="siignup.click.desc" defaultMessage="If you already registered click to"/>&nbsp;  &nbsp; 
                  <Link className="" to="/login" >
                    <FormattedMessage id="signup.login" defaultMessage="lOG IN"/>
                  </Link>
                </div>
              </div>                 
            </div>
            <div className="float-left">
              <div
                className="spinner-border text-primary ml-2"
                role="status"
                id="register-loader"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </form>
        </div>
      );      
    }
    else{
      //console.log(' Inside  '+JSON.stringify(this.state.userRole));
      const phonenoInd  = <FormattedHTMLMessage id="signup.phone" defaultMessage="Phone No *"/>
      const companyInd  = <FormattedHTMLMessage id="signup.company" defaultMessage="Compay Name *"/>
      const designationInd  = <FormattedHTMLMessage id="signup.designation" defaultMessage="Designation *"/>
          
      return (
        <div className="col-md-6 col-sm-12 mx-auto col"  style={{marginTop:"10%"}}>
           {/* <div id="email" style={{display:"none"}}>
          <div className="alert alert-danger"  role="alert">
            Email already Exist.
          </div>
          </div> */}
             <h4 className="modal-title text-center text-uppercase w-100 medium text-grey font-20">
                   <FormattedMessage
                      id="head.register"
                      defaultMessage="register"
                    />
                </h4>
                {localStorage.getItem("Linktest")?this.msgMessage():""}
                {this.errMessage()}
                {this.errorMessage()}
          <form
            id="register-form"
            onSubmit={this.props.handleSubmit(e => this.registerUser(e))}
            style={{display:"block",padding: "15px 26px",border: "1px solid #000"}}
          >
            <Field name="role" component={this.renderSelectField} label={youare} onChange={e => this.changeUserRole(e)} >
               {/* <option value="">Registered as</option> */}
              {this.renderRoles(Roles)}
            </Field>          
             {/* <div> 
            <select
                      defaultValue="particular"
                                     className="form-control" id="partial_full_disability"
                                       name="role" onChange={e => this.changeUserRole(e)} onSelect={value => this.changeUserRole(value)}>
                                        <option value="0">Select partial full disability</option>
                                           <option
                                            value={1}>Particular
                                              </option>
                                              <option
                                               value={1}>Individual
                                                </option>
            </select>     
            </div> */}
            <Field
              name="firstName"
              type="text"
              component={this.renderField}
              label={firstname}
              role='2'
              
            />
            <Field
              name="lastName"
              type="text"
              component={this.renderField}
              label={lastname}
            />
            <Field
              name="email"
              type="email"
              component={this.renderField}
              label={emailaddress}
            />
             {this.errorMessage()}
              {/* <Field
              name="address"
              type="text"
              component={this.renderField}
              label={address}
            />
            <Field
              name="phone"
              type="tel"
              component={this.renderField}
              label={phonenoInd}
            /> */}
            <div id="hrDetail" style={{ display: "none"}}>
              <Field
                name="companyName"
                type="text"
                component={this.renderField}
                label={companyInd}
              />
              <Field
                name="designation"
                type="text"
                component={this.renderField}
                label={designationInd}
              />
            </div>
            <Field
              name="password"
              type="password"
              component={this.renderField}
              label={password}
            />
            <Field
              name="confirmPass"
              type="password"
              component={this.renderField}
              label={cpassword}
            />
             <FormattedMessage id="signup.mandaory" defaultMessage="* mandatory"/><br/>
            <input name="agree" placeholder="" type="checkbox"  required></input>
            <span className="ml-3 mb-5" style={{ fontSize:"13px" }}> 
                <FormattedMessage id="signup.consent"  defaultMessage="consent"/>
                <a href=""><FormattedMessage id="signup.condition"  defaultMessage="consent"/></a>
                <FormattedMessage id="signup.consent2"  defaultMessage="consent"/>
                <a href=""><FormattedMessage id="signup.term"  defaultMessage="consent"/></a>
                <FormattedMessage id="signup.consent3"  defaultMessage="consent"/>
            </span>
           
            <div className="form-group">
              <div className="row">
             
                <div className="m-auto d-flex">
                  <button type="submit" name="register-submit" id="register-submit" tabIndex="4"  className="form-control btn btn-primary mr-3 whitespacenowrap" ref="registerBtn">
                    <FormattedMessage id="signup.signup" defaultMessage=""/>
                  </button>
                  {/* <button type="button" name="register-submit" id="register-submit" tabIndex="4"  className="form-control btn btn-secondary whitespacenowrap" ref="sbmt" onClick={e => this.showLogin('#login-form-link')}>
                    <FormattedMessage id="signup.login" defaultMessage="lOG IN"/>
                  </button> */}
                </div>
              </div>
              <hr/>
              <div className="row">
                <div className="m-auto d-flex">
                  <FormattedMessage id="siignup.click.desc" defaultMessage="If you already registered click to"/>&nbsp;  &nbsp; 
      <Link className="" to="/login" >
                    <FormattedMessage id="signup.login" defaultMessage="lOG IN"/>
                  </Link>
                </div>
              </div>                 
            </div>
            <div className="float-left">
              <div
                className="spinner-border text-primary ml-2"
                role="status"
                id="register-loader"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </form>
        </div>
      );      
    }
  }
}

const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "First Name is required";
  }
  if (!values.address) {
    errors.address = "address is required";
  }
  if (!values.lastName) {
    errors.lastName = "Last Name is required";
  }
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.role) {
    // errors.role = "*Field required";
    values.role=3;
  }
  if (values.role == 3) {
    //console.log('inside validation '+values.role+' -c- '+values.phone);    
    if (!values.phone) {
      errors.phone = "*Field required";
    }
    else if(/^(?:\+\d{11}|\d{10})$/i.test(values.phone)==false){
      errors.phone = "Invalid phone number";
    }
    if (!values.companyName) {
      errors.companyName = "*Field required";
    }
    if (!values.designation) {
      errors.designation = "*Field required";
    }
  }
  if (values.role == 4) {
    //console.log('inside validation '+values.role+' -c- '+values.phone);    
    if (!values.phone) {
      errors.phone = "*Field required";
    }
    else if(/^(?:\+\d{11}|\d{10})$/i.test(values.phone)==false){
      errors.phone = "Invalid phone number";
    }
    if (!values.companyName) {
      errors.companyName = "*Field required";
    }
    if (!values.designation) {
      errors.designation = "*Field required";
    }
  }
  if (!values.password) {
    errors.password = "Password is equired";
  }
  if (!values.confirmPass) {
    errors.confirmPass = "Confirm password is required";
  } else if (
    values.password &&
    values.confirmPass &&
    values.password !== values.confirmPass
  ) {
    errors.confirmPass = "Password miss match";
  }
  return errors;
};

const mapStateToProps = state => {
  //console.log(state);
  return {
    RegisterValidation: state.form.RegisterValidation,
    signupError: state.auth.signupError,
    errorMessage: state.auth.loginError,
  };
};

Register = connect(
  mapStateToProps,
  { register, reset,clearMessage }
)(Register);

export default reduxForm({
  form: "RegisterValidation",
  enableReinitialize: true,
  validate
})(Register);
