import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, reduxForm, reset } from "redux-form";
import { FormattedMessage,FormattedHTMLMessage } from "react-intl";
import { register } from "../../actions";
import $ from "jquery";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: 2,
      lang: null,
      mobi:"",
    };
  }  

  

  renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div className="form-group">
      <label>{label}</label>
      <input
        {...input}
        placeholder=''
        type={type}
        className="form-control"
      />
      {touched && (error && <small className="text-danger">{error}</small>)}
    </div>
  );
  
  renderSelectField = ({
    input,
    label,
    meta: { touched, error },
    children,
  }) => (
    <div className="form-group">
      <label>{label}</label>
      <select {...input}   value={this.state.userRole} className="form-control">
        {children}
      </select>
      {/* {touched && (error && <small className="text-danger">{error}</small>)} */}
    </div>
  ); 

  registerUser(values) {
    console.log(values);
    $("#register-loader").show();
    this.refs.registerBtn.setAttribute("disabled", "disabled");
   // pass the languages in values \
    //var language = localStorage.getItem("lang");
    values.language = this.props.lang;
    //console.log('registerUser '+JSON.stringify(values, null, 4));

    this.props.register(values, this.refs.registerBtn, response => {
      //console.log(' errors++++ '+JSON.stringify(response)); 
      if (response === "success") {
        this.props.reset("RegisterValidation");
        // on successfull signup show login form 
        $("#login-form").delay(100).fadeIn(100);
        $("#register-form").fadeOut(100);
        $("#register-form-link").removeClass("active");
        $('#login-form-link').addClass("active");
      }else{
        console.log(' errors*** '+response);
        $("#loginModal").scrollTop(0);
      }
    });
  }

  renderRoles(roles) {
    //console.log(JSON.stringify(this.props.lang)+' -- '+localStorage.getItem("lang"));
  
    if(this.props.lang !== "en"){
      var ind  = 'Particulier';
      var prof = 'Consultant.e';
      var ent  = 'Entreprise';
      
    }else{
      var ind  = 'Individual';
      var prof = 'Consultant.e';
      var ent  = 'Enterprise';
      console.log(roles);
 
    }
    if (roles.length > 0) {
      // console.log(this.state.userRole); 
      return roles.reverse().map(role => {

        return (
        
          <option key={role.id} value={role.id}>            
            {role.id === 3 ? prof :role.id === 2 ? ind: ent} 
            {/* {role.name} */}
          </option>
        );
      });
    }
  }

  showLogin(e) {
    $("#login-form").delay(100).fadeIn(100);
    $("#register-form").fadeOut(100);
    $("#register-form-link").removeClass("active");
    //console.log('Inside Register'+e);
    if(e === '#login-form-link'){
      $('.modal-title').text('LOGIN');            
      $(e).addClass("active");
    }else{
      $(e.target).addClass("active");
    }
  }

  // changeUserRole(e) {
  //   //console.log('Hurrey '+e.target.value); 
  //   if(e.target.value == 2){
  //     this.setState({
  //       userRole: 2
  //     });   
  //     console.log(this.state.userRole);   
  //     $("#hrDetail").hide();
  //   }else if(e.target.value === null ){
  //     $("#hrDetail").show();
  //     this.setState({
  //       userRole: 3
  //     });           
     
  //   }else{
  //     $("#hrDetail").show();
  //     this.setState({
  //       userRole: 3
  //     });  
  //   }
  // }
  changeUserRole(e) {
    console.log('Hurrey '+e.target.role); 
    if(e.target.value == 2){
      this.setState({
        userRole: 2
      });   
      console.log(this.state.userRole);   
      $("#hrDetail").hide();
    }
    else if(e.target.value == 4 ){
      $("#hrDetail").show();
      this.setState({
        userRole: 4
      });           
     
    }
    else if(e.target.value === null ){
      $("#hrDetail").show();
      this.setState({
        userRole: 3
      });           
     
    }else{
      $("#hrDetail").show();
      this.setState({
        userRole: 3
      });  
    }
  }
  errorMessage() {
   

    console.log(this.props.signupError+' --  '+this.props.successMessage+' props '+JSON.stringify(this.props));
    // check for signup error message
    if (this.props.errorMessage == "The email has already been taken. ") {
      return (
        <div  class=" alert alert-danger alert-dismissible fade show "  role="alert">
          {this.props.errorMessage}
        </div>
      );
    }
    else if (this.props.errorMessage !== null && this.props.errorMessage !== "" && this.props.errorMessage !== "The email has already been taken.") {
      return (
        <div  class="alert alert-danger alert-dismissible fade show "  role="alert">
          {this.props.errorMessage}
        </div>
      );
    }

    // if (this.props.errorMessage !== null && this.props.errorMessage !== "") {
    //   return (
    //     <div  class="alert alert-danger alert-dismissible fade show "  role="alert">
    //       {this.props.errorMessage}
    //     </div>
    //   );
    // }
    // check for loginn error message
    // if (this.props.errorMessage !== null && this.props.errorMessage !== "") {
    //   return (
    //     <div className="alert alert-danger" role="alert">
    //       {this.props.errorMessage}
    //     </div>
    //   );
    // }
    // if (
    //   this.props.successMessage !== null &&
    //   this.props.successMessage !== ""
    // ) {
    //   return (
    //     <div className="alert alert-success" role="alert">
    //       {this.props.successMessage}
    //     </div>
    //   );
    // }

    setTimeout(() => {
      this.props.clearMessage();
    }, 80000);
  }

 

  render() {
    const { Roles } = this.props;
    const firstname  = <FormattedMessage id="signup.firstname" defaultMessage="First Name"/>
    const lastname  = <FormattedMessage id="signup.lastname" defaultMessage="Last Name"/>
    const emailaddress  = <FormattedMessage id="signup.email" defaultMessage="Email"/>
    const password  = <FormattedMessage id="signup.password" defaultMessage="Password"/>
    const cpassword  = <FormattedMessage id="signup.cpassword" defaultMessage="Confirm Password"/>
    const youare  = <FormattedMessage id="signup.youare.hr" defaultMessage="Are you Individual / Professional *"/>
    const path = window.location.pathname;
    const param = path.split('/')[2]; 
    console.log(param);

    //var language = localStorage.getItem("lang");
    //console.log('Render '+language);
    if(this.state.userRole == 3){
      //console.log(' Inside  '+JSON.stringify(this.state.userRole));
      const phonenoHr  = <FormattedHTMLMessage id="signup.phone.hr" defaultMessage="Phone No *"/>
      const companyHr  = <FormattedHTMLMessage id="signup.company.hr" defaultMessage="Compay Name *"/>
      const designationHr  = <FormattedHTMLMessage id="signup.designation.hr" defaultMessage="Designation *"/>
      return (
        <div>
       
            
          <form
            id="register-form"
            onSubmit={this.props.handleSubmit(e => this.registerUser(e))}
          >
            <Field name="role" component={this.renderSelectField} label={youare} onChange={e => this.changeUserRole(e)} >
              {/* <option value="3" selected>Professional</option> */}
              {this.renderRoles(Roles)}
            </Field>          
            <Field
              name="firstName"
              type="text"
              component={this.renderField}
              label={firstname}
            />
            <Field
              name="lastName"
              type="text"
              component={this.renderField}
              label={lastname}
            />
            <Field
              name="email"
              type="email"
              component={this.renderField}
              label={emailaddress}
            />
            {this.errorMessage()}
            <Field
              name="phone"
              type="tel"
              placeholder="eg.09876556767"
              component={this.renderField}
              label={phonenoHr}
            />
            <div id="hrDetail" style={{ display: ""}}>
              <Field
                name="companyName"
                type="text"
                component={this.renderField}
                label={companyHr}
              />
              <Field
                name="designation"
                type="text"
                component={this.renderField}
                label={designationHr}
              />
            </div>
            <Field
              name="password"
              type="password"
              component={this.renderField}
              label={password}
            />
            <Field
              name="confirmPass"
              type="password"
              component={this.renderField}
              label={cpassword}
            />
            <Field
              name="registered_by"
              type="hidden"
              component={this.renderField}
              label={param}
            />
            <div className="form-group">
              <div className="row">
              <FormattedMessage id="signup.mandaory" defaultMessage="* mandatory"/>
                <div className="m-auto d-flex">
                  <button type="submit" name="register-submit" id="register-submit" tabIndex="4"  className="form-control btn btn-primary mr-3 whitespacenowrap" ref="registerBtn">
                    <FormattedHTMLMessage id="signup.signup" defaultMessage=""/>
                  </button>
                  {/* <button type="button" name="register-submit" id="register-submit" tabIndex="4"  className="form-control btn btn-secondary whitespacenowrap" ref="sbmt" onClick={e => this.showLogin('#login-form-link')}>
                    <FormattedMessage id="signup.login" defaultMessage="lOG IN"/>
                  </button> */}
                </div>
              </div>
              <hr/>
              <div className="row">
                <div className="m-auto d-flex">
                  <FormattedMessage id="siignup.click.desc" defaultMessage="If you already registered click to"/>&nbsp;  &nbsp; 
                  <Link className="" to="#" onClick={e => this.showLogin('#login-form-link')}>
                    <FormattedMessage id="signup.login" defaultMessage="lOG IN"/>
                  </Link>
                </div>
              </div>                 
            </div>
            <div className="float-left">
              <div
                className="spinner-border text-primary ml-2"
                role="status"
                id="register-loader"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </form>
        </div>
      );      
    }
    
    if(this.state.userRole == 4){
      //console.log(' Inside  '+JSON.stringify(this.state.userRole));
      // const phonenoHr  = <FormattedHTMLMessage id="signup.phone.hr" defaultMessage="Phone No *"/>
      // const companyHr  = <FormattedHTMLMessage id="signup.company.hr" defaultMessage="Compay Name *"/>
      // const designationHr  = <FormattedHTMLMessage id="signup.designation.hr" defaultMessage="Designation *"/>
      const phonenoHr  = <FormattedHTMLMessage id="signup.phone" defaultMessage="Phone No *"/>
      const companyHr  = <FormattedHTMLMessage id="signup.company" defaultMessage="Compay Name *"/>
      const designationHr = <FormattedHTMLMessage id="signup.designation" defaultMessage="Designation *"/>
      return (
        <div>
       
            
          <form
            id="register-form"
            onSubmit={this.props.handleSubmit(e => this.registerUser(e))}
          >
            <Field name="role" component={this.renderSelectField} label={youare} onChange={e => this.changeUserRole(e)} >
              {/* <option value="3" selected>Professional</option> */}
              {this.renderRoles(Roles)}
            </Field>          
            <Field
              name="firstName"
              type="text"
              component={this.renderField}
              label={firstname}
            />
            <Field
              name="lastName"
              type="text"
              component={this.renderField}
              label={lastname}
            />
            <Field
              name="email"
              type="email"
              component={this.renderField}
              label={emailaddress}
            />
            <Field
              name="phone"
              type="tel"
              placeholder="eg.09876556767"
              component={this.renderField}
              label={phonenoHr}
            />
            <div id="hrDetail" style={{ display: ""}}>
              <Field
                name="companyName"
                type="text"
                component={this.renderField}
                label={companyHr}
              />
              <Field
                name="designation"
                type="text"
                component={this.renderField}
                label={designationHr}
              />
            </div>
            <Field
              name="password"
              type="password"
              component={this.renderField}
              label={password}
            />
            <Field
              name="confirmPass"
              type="password"
              component={this.renderField}
              label={cpassword}
            />
            <Field
              name="registered_by"
              type="hidden"
              component={this.renderField}
              label={param}
            />
            <div className="form-group">
              <div className="row">
              <FormattedMessage id="signup.mandaory" defaultMessage="* mandatory"/>
                <div className="m-auto d-flex">
                  <button type="submit" name="register-submit" id="register-submit" tabIndex="4"  className="form-control btn btn-primary mr-3 whitespacenowrap" ref="registerBtn">
                    <FormattedHTMLMessage id="signup.signup" defaultMessage=""/>
                  </button>
                  {/* <button type="button" name="register-submit" id="register-submit" tabIndex="4"  className="form-control btn btn-secondary whitespacenowrap" ref="sbmt" onClick={e => this.showLogin('#login-form-link')}>
                    <FormattedMessage id="signup.login" defaultMessage="lOG IN"/>
                  </button> */}
                </div>
              </div>
              <hr/>
              <div className="row">
                <div className="m-auto d-flex">
                  <FormattedMessage id="siignup.click.desc" defaultMessage="If you already registered click to"/>&nbsp;  &nbsp; 
                  <Link className="" to="#" onClick={e => this.showLogin('#login-form-link')}>
                    <FormattedMessage id="signup.login" defaultMessage="lOG IN"/>
                  </Link>
                </div>
              </div>                 
            </div>
            <div className="float-left">
              <div
                className="spinner-border text-primary ml-2"
                role="status"
                id="register-loader"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </form>
        </div>
      );      
    }
    
    
    else{
      //console.log(' Inside  '+JSON.stringify(this.state.userRole));
      const phonenoInd  = <FormattedHTMLMessage id="signup.phone" defaultMessage="Phone No *"/>
      const companyInd  = <FormattedHTMLMessage id="signup.company" defaultMessage="Compay Name *"/>
      const designationInd  = <FormattedHTMLMessage id="signup.designation" defaultMessage="Designation *"/>
          
      return (
        <div className="col-md-12">
       
          <form
            id="register-form"
            onSubmit={this.props.handleSubmit(e => this.registerUser(e))}
          >
            <Field name="role" component={this.renderSelectField} label={youare} onChange={e => this.changeUserRole(e)} >
              {/* <option value="">Registered as</option> */}
              {this.renderRoles(Roles)}
            </Field>          
            <Field
              name="firstName"
              type="text"
              component={this.renderField}
              label={firstname}
            />
            <Field
              name="lastName"
              type="text"
              component={this.renderField}
              label={lastname}
            />
            <Field
              name="email"
              type="email"
              component={this.renderField}
              label={emailaddress}
            />
            
            {/* <Field
              name="phone"
              type="tel"
              placeholder="eg.09876556767"
              component={this.renderField}
              label={phonenoInd}
            /> */}
            {/* <div id="hrDetail" style={{ display: "none"}}>
              <Field
                name="companyName"
                type="text"
                component={this.renderField}
                label={companyInd}
              />
              <Field
                name="designation"
                type="text"
                component={this.renderField}
                label={designationInd}
              />
            </div> */}
            <Field
              name="password"
              type="password"
              component={this.renderField}
              label={password}
            />
            <Field
              name="confirmPass"
              type="password"
              component={this.renderField}
              label={cpassword}
            />
            <Field
              name="registered_by"
              type="hidden"
              component={this.renderField}
              label={param}
            />
            <div className="form-group">
              <div className="row">
              <FormattedMessage id="signup.mandaory" defaultMessage="* mandatory"/>
                <div className="m-auto d-flex">
                  <button type="submit" name="register-submit" id="register-submit" tabIndex="4"  className="form-control btn btn-primary mr-3 whitespacenowrap" ref="registerBtn">
                    <FormattedMessage id="signup.signup" defaultMessage=""/>
                  </button>
                  {/* <button type="button" name="register-submit" id="register-submit" tabIndex="4"  className="form-control btn btn-secondary whitespacenowrap" ref="sbmt" onClick={e => this.showLogin('#login-form-link')}>
                    <FormattedMessage id="signup.login" defaultMessage="lOG IN"/>
                  </button> */}
                </div>
              </div>
              <hr/>
              <div className="row">
                <div className="m-auto d-flex">
                  <FormattedMessage id="siignup.click.desc" defaultMessage="If you already registered click to"/>&nbsp;  &nbsp; 
                  <Link className="" to="#" onClick={e => this.showLogin('#login-form-link')}>
                    <FormattedMessage id="signup.login" defaultMessage="lOG IN"/>
                  </Link>
                </div>
              </div>                 
            </div>
            <div className="float-left">
              <div
                className="spinner-border text-primary ml-2"
                role="status"
                id="register-loader"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </form>
        </div>
      );      
    }
  }
}

const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "First Name is required";
  }
  if (!values.lastName) {
    errors.lastName = "Last Name is required";
  }
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.role) {
    // errors.role = "*Field required";
    values.role=3;
  }
  if (values.role == 3) {
    //console.log('inside validation '+values.role+' -c- '+values.phone);    
    if (!values.phone) {
      errors.phone = "*Field required";
    }
    else if(/^(?:\+\d{11}|\d{10})$/i.test(values.phone)==false){
      errors.phone = "Invalid phone number";
    }
    if (!values.companyName) {
      errors.companyName = "*Field required";
    }
    if (!values.designation) {
      errors.designation = "*Field required";
    }
  }
  if (values.role == 4) {
    //console.log('inside validation '+values.role+' -c- '+values.phone);    
    if (!values.phone) {
      errors.phone = "*Field required";
    }
    else if(/^(?:\+\d{11}|\d{10})$/i.test(values.phone)==false){
      errors.phone = "Invalid phone number";
    }
    if (!values.companyName) {
      errors.companyName = "*Field required";
    }
    if (!values.designation) {
      errors.designation = "*Field required";
    }
  }
  if (!values.password) {
    errors.password = "Password is equired";
  }
  if (!values.confirmPass) {
    errors.confirmPass = "Confirm password is required";
  } else if (
    values.password &&
    values.confirmPass &&
    values.password !== values.confirmPass
  ) {
    errors.confirmPass = "Password miss match";
  }
  return errors;
};

const mapStateToProps = state => {
  //console.log(state);
  return {
    RegisterValidation: state.form.RegisterValidation
  };
};

Register = connect(
  mapStateToProps,
  { register, reset }
)(Register);

export default reduxForm({
  form: "RegisterValidation",
  enableReinitialize: true,
  validate
})(Register);
