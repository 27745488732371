import axios from "axios";
import { history } from "../helper/History";
import $ from "jquery";
import { ROOT_URL } from "../helper/Config";
export const SIGN_IN 			      = "user_login";
export const REGISTER 			    = "user_register";
export const REGISTER_ERROR 		= "signup_duplicate_email";
export const SIGN_ERROR 		    = "login_error";
export const SIGN_OUT 			    = "user_logout";
export const CLEAR_MESSAGE 		  = "clear_message";
export const SET_LOCALE 		    = "set_locale";
export const CONTACT_US 	      = "contact_us";
export const CLEAR_CONT_MESSAGE = "contact_message";
export const NEWSLETTER 	      = "newsletter";
export const CONTACT_HR 	      = "contact_hr";
export const CLEAR_CONT_HR_MSG = "contact_hr_message";
export const CONTACT_COACH 	    = "contact_coach";
export const CLEAR_CONT_COACH_MSG = "contact_coach_message";
export const SHARE_TEST_LINK      = "share_test_link";
export const CLEAR_SHARELINK_MESSAGE          = "clear_sharelink_message";
export const SHARE_LINK                       = "share_link"; 
export const PROFILE_MATCHING                 = "profile_matching"; 
export const CLEAR_PROFILE_MATCHING_MESSAGE   = "clear_profile_matching_message"; 



export function login(userData, btnCrtrl) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/login`, userData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
      .then(response => {
        //console.log(response);
        //console.log('UserDetail'+JSON.stringify(response.data));
        if (response.data.token && response.data.token !== "") {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userName", response.data.name);
          localStorage.setItem("userEmail", response.data.email);
          localStorage.setItem("userRole", response.data.role);
          localStorage.setItem("userId", response.data.id);
          localStorage.setItem("purchasedate", response.data.purchase);
          localStorage.setItem("subscription", response.data.subscription);
          localStorage.setItem("enddate", response.data.End_date);
          

          if(response.data.role=='User'){
          dispatch({
            type: SIGN_IN,
            payload: response
          });
          $("#loginModal").modal("hide");
          history.push("/");
        }  
        else if(userData.redirectpage=='Enterprise'){
          dispatch({
            type: SIGN_IN,
            payload: response
          });
          $("#loginModal").modal("hide");
          history.push("/Enterprisesub");
        } 
        else if(userData.redirectpage=='Consultant'){
          dispatch({
            type: SIGN_IN,
            payload: response
          });
          $("#loginModal").modal("hide");
          history.push("/NewBuy");
        } 
        // else if(userData.redirectpage=='Conbuytest'){
        //   dispatch({
        //     type: SIGN_IN,
        //     payload: response
        //   });
        //   $("#loginModal").modal("hide");
        //   history.push("/NewBuy");
        // } 
        else if(response.data.role=='Hr'){
          dispatch({
            type: SIGN_IN,
            payload: response
          });
          $("#loginModal").modal("hide");
          history.push("/youareaconsultant");
        } 
        else if(response.data.role=='Enterprise'){
          dispatch({
            type: SIGN_IN,
            payload: response
          });
          $("#loginModal").modal("hide");
          history.push("/youareacompany");
        } 
      
      
      }
        
        else {
          console.log("error in response");     
        }
        btnCrtrl.removeAttribute("disabled");
        $("#login-loader").hide();
      })
      .catch(error => {
        console.log(error.response);
        dispatch({
          type: SIGN_ERROR,
          payload: error.response.data.error
        });
        btnCrtrl.removeAttribute("disabled");
        $("#login-loader").hide();
      });
  };
}

export function register(userData, btnCrtrl, callback) {
  //console.log('register '+JSON.stringify(userData, null, 4));
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/register`, userData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
      .then(response => {
        console.log(response);
        //console.log(response.status);
        if (response.data.message && response.data.message !== "") {
          dispatch({
            type: REGISTER,
            payload: response.data.message
          });
          // console.log(response);
          if(response.data.res && response.data.res !=="") {
            localStorage.setItem("token", response.data.res.token);
            localStorage.setItem("userName", response.data.res.name);
            localStorage.setItem("userEmail", response.data.res.email);
            localStorage.setItem("userRole", response.data.res.role);
            localStorage.setItem("userId", response.data.res.id);
            localStorage.setItem("purchasedate", response.data.res.purchase);
            localStorage.setItem("subscription", response.data.res.subscription);
            // localStorage.setItem("enddate", response.data.End_date);
            if(response.data.res.role=='User'){
            dispatch({
              type: SIGN_IN,
              payload: response
            });
            
            if(response.data.message && response.data.res.test ==="happiness") {
              $("#loginModal").modal("hide");
              history.push("/happiness");
            } else if(response.data.message && response.data.res.test ==="target") {
              $("#loginModal").modal("hide");
              history.push("/target");
            } else if(response.data.message && response.data.res.test ==="individual") {
              $("#loginModal").modal("hide");
              history.push("/individual");
            } else {
              $("#loginModal").modal("hide");
              history.push("/");
            }
          } 
          } else {
            callback("success");
          }
          
          //$("#loginModal").modal("hide");
          //history.push("/hr");
        } else {
          dispatch({
            type: REGISTER_ERROR,
            payload: response.data.errors.email
          });
          callback("errors",response.data.errors.email);         
          console.log(' action error '+JSON.stringify(response.data.errors.email));  
        }
        btnCrtrl.removeAttribute("disabled");
        $("#register-loader").hide();
      })
      .catch(error => {
        console.log(error.request.response);
        var myObj, x ;
        var errorText = '';
        myObj = JSON.parse(error.request.response).errors;
        console.log(myObj);
        for (x in myObj) {
          errorText += myObj[x] + " ";
        }
        dispatch({
          type: SIGN_ERROR,
          payload: errorText
        });
        callback("errors");
        btnCrtrl.removeAttribute("disabled");
        $("#register-loader").hide();
      });
  };
}

export function logout() {
  return dispatch => {
    localStorage.clear();
    dispatch({ type: SIGN_OUT });
    history.push("/");
  };
}

export function clearMessage() {
  return dispatch => {
    dispatch({ type: CLEAR_MESSAGE });
  };
}
export function clearContactMessage() {
  console.log('Action clearContactMessage');
  return dispatch => {
    dispatch({ type: CLEAR_CONT_MESSAGE });
  };
}
export function clearContHrMessage() {
  //console.log('Action clearContHrMessage');
  return dispatch => {
    dispatch({ type: CLEAR_CONT_HR_MSG });
  };
}
export function clearContactCoachMessage() {
  //console.log('Action clearContactCoachMessage');
  return dispatch => {
    dispatch({ type: CLEAR_CONT_COACH_MSG });
  };
}

export function clearSharelinkMessage() {
  console.log('Action CLEAR_SHARELINK_MESSAGE');
  return dispatch => {
    dispatch({ type: CLEAR_SHARELINK_MESSAGE });
  };
}

export function setLocale(lang) {
  return dispatch => {
    localStorage.setItem("lang", lang);
    dispatch({ type: SET_LOCALE, payload: lang });
  };
}

export function updateTestConsume(token, data, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/update-test-consume`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        callback("success");
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function contact(userData, btnCrtrl, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/contactus`, userData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
      .then(response => {
        //console.log('Inside contac us '+JSON.stringify(response));
        //console.log(response.status);
        if (response.data.message && response.data.message !== "") {
          dispatch({
            type: CONTACT_US,
            payload: response.data.message
          });
          callback("success");
          //$("#loginModal").modal("hide");
          //history.push("/hr");
        } else {
          //console.log("error in response");
          dispatch({
            type: CONTACT_US,
            payload: ""
          });
        }
        btnCrtrl.removeAttribute("disabled");
        $("#register-loader").hide();
      })
      .catch(error => {
        console.log(error.response);

        dispatch({
          type: CONTACT_US,
          payload: ""
        });
        btnCrtrl.removeAttribute("disabled");
        $("#register-loader").hide();
      });
  };
}
export function contactacoach(userData, btnCrtrl, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/contacttocoach`, userData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
      .then(response => {
        //console.log('Inside contac us '+JSON.stringify(response));
        //console.log(response.status);
        if (response.data.message && response.data.message !== "") {
          dispatch({
            type: CONTACT_COACH,
            payload: response.data.message
          });
          callback("success");
          //$("#loginModal").modal("hide");
          //history.push("/hr");
        } else {
          //console.log("error in response");
          dispatch({
            type: CONTACT_COACH,
            payload: ""
          });
        }
        btnCrtrl.removeAttribute("disabled");
        $("#register-loader").hide();
      })
      .catch(error => {
        //console.log(error.response);
        dispatch({
          type: CONTACT_COACH,
          payload: ""
        });
        btnCrtrl.removeAttribute("disabled");
        $("#register-loader").hide();
      });
  };
}

export function contactToHr(userData, btnCrtrl, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/contacthr`, userData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
      .then(response => {
        console.log(response);
        //console.log(response.status);
        if (response.data.message && response.data.message !== "") {
          dispatch({
            type: CONTACT_HR,
            payload: response.data.message
          });
          callback("success");
          //$("#loginModal").modal("hide");
          //history.push("/hr");
        } else {
          console.log("error in response");
          dispatch({
            type: CONTACT_HR,
            payload: response.data.error
          });                    
        }
        btnCrtrl.removeAttribute("disabled");
        $("#register-loader").hide();
      })
      .catch(error => {
        console.log(error.response);
        dispatch({
          type: CONTACT_HR,
          payload: ""
        });
        btnCrtrl.removeAttribute("disabled");
        $("#register-loader").hide();
      });
  };
}

export function contactToEnterprise(userData, btnCrtrl, callback) {
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/contactenterprise`, userData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
      .then(response => {
        console.log(response);
        //console.log(response.status);
        if (response.data.message && response.data.message !== "") {
          dispatch({
            type: CONTACT_HR,
            payload: response.data.message
          });
          callback("success");
          //$("#loginModal").modal("hide");
          //history.push("/hr");
        } else {
          console.log("error in response");
          dispatch({
            type: CONTACT_HR,
            payload: response.data.error
          });                    
        }
        btnCrtrl.removeAttribute("disabled");
        $("#register-loader").hide();
      })
      .catch(error => {
        console.log(error.response);
        dispatch({
          type: CONTACT_HR,
          payload: ""
        });
        btnCrtrl.removeAttribute("disabled");
        $("#register-loader").hide();
      });
  };
}

export function newsletter(userData, btnCrtrl, callback) {

  userData.lang=localStorage.getItem('lang')
  console.log(userData)
  return dispatch => {
    return axios
      .post(`${ROOT_URL}/newsletter`, userData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
      .then(response => {
        //console.log(response);
        //console.log(response.status);
        if (response.data.message && response.data.message !== "") {
          dispatch({
            type: NEWSLETTER,
            payload: response.data.message
          });
          callback("success");
          //$("#loginModal").modal("hide");
          //history.push("/hr");
        } else {
          //console.log("error in response++++"+response.data.error);
          dispatch({
            type: NEWSLETTER,
            payload: response.data.error
          });          
        }
        btnCrtrl.removeAttribute("disabled");
        $("#newsletter-loader").hide();
      })
      .catch(error => {
        console.log(error.response);

        dispatch({
          type: NEWSLETTER,
          payload: ""
        });
        btnCrtrl.removeAttribute("disabled");
        $("#newsletter-loader").hide();
      });
  };
}

// is used to send test link by Hr to Candidate and keep its track records
export function shareTestLink(userData, btnCrtrl, callback) {
  return dispatch => {
    return axios.post(`${ROOT_URL}/share-test-link`, userData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }).then(response => {
        //console.log('Inside contac us '+JSON.stringify(response));
        //console.log(response.status);
        if (response.data.message && response.data.message !== "") {
          dispatch({
            type: SHARE_LINK,
            payload: response.data.message
          });
          callback("success");
          //$("#loginModal").modal("hide");
          //history.push("/hr");
        } else {
          //console.log("error in response");
          dispatch({
            type: SHARE_LINK,
            payload: ""
          });
        }
        btnCrtrl.removeAttribute("disabled");
        $("#sharelink-loader").hide();
      }).catch(error => {
        console.log(error.response);
        dispatch({
          type: SHARE_LINK,
          payload: ""
        });
        btnCrtrl.removeAttribute("disabled");
        $("#sharelink-loader").hide();
      });
  };
}

